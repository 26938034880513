import React from 'react'

function Settings() {
  return (
    <div>
      <h2>Settings</h2>
    </div>
  )
}

export default Settings
