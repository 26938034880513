import React from 'react'
import '../assets/css/MyPocketAI.css'

function MyPocketAI() {
  return (
    <div className='bot-container'>
      <h2>MyPocketAI</h2>
      <p>Launching on 2/12</p>
    </div>
  )
}

export default MyPocketAI
